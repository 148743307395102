import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from "dayjs";
import debounce from "debounce";
import { GET_LOGISTICS_LIST } from "@/graphql/admin/delivery";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE, imageUrlApi } from "@/config";
export default {
  created: function created() {
    document.title = "\u0E02\u0E19\u0E2A\u0E48\u0E07\u0E20\u0E32\u0E22\u0E43\u0E19\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28 | ".concat(WEBSITE);
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32",
      route: "/admin/setting"
    }, {
      title: "\u0E02\u0E19\u0E2A\u0E48\u0E07\u0E20\u0E32\u0E22\u0E43\u0E19\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28"
    }]);
  },
  data: function data() {
    return {
      countryList: {
        thai: "ไทย",
        lao: "ลาว"
      },
      page: 1,
      pageSize: 20,
      search: ""
    };
  },
  methods: {
    imageUrlApi: imageUrlApi,
    debounceInput: debounce(function (e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    format_date: function format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    }
  },
  apollo: {
    systemLogisticsList: {
      query: GET_LOGISTICS_LIST,
      variables: function variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};